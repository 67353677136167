/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External links
        $('a').filter(function(){
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('external').attr('target', '_blank');

        // Buttons w/ toggle
        $('.is-hidden').hide();

        var moreText = 'Read More &#43;',
            lessText = 'Read Less &#45;';

        $('.c-btn--more').click(function(e) {
          e.preventDefault();

          var btnText = $('.is-hidden').is(':visible') ? moreText : lessText;

          $(this).html(btnText);

          $('.is-hidden').slideToggle();
        });

        // Buttons w/ scroll
        $('.c-btn--scroll').click(function(e) {
          e.preventDefault();

          var section = $(this).attr('href');

          $('html, body').stop().animate({
            scrollTop: $(''+section+'').offset().top
          }, 1000);
        });

        // Navigation
        if (viewportWidth < 1024) {
          $('.c-nav__toggle').on('click', function(e){
            e.preventDefault();

            $(this).toggleClass('is-active');

            $('.c-nav').slideToggle();
          });
        }

        // Menu w/ highlight on scroll
        /*if (viewportWidth > 1024) {
          $(window).scroll(function() {
            var scrollX = $(this).scrollTop();

            $('.c-home').each(function() {
              var sectionTop = $(this).offset().top;

              if (scrollX >= sectionTop) {
                var sectionID = $(this).attr('id');

                $('.c-nav__list li').removeClass('is-active');
                $('.c-nav__list li a[href="#'+sectionID+'"]').parent('li').addClass('is-active');
              }
            });
          });
        }*/

        // Menu w/ highlight on click
        if (!$('body.single').length) {
          $('.c-nav__list a').click(function(e) {
            e.preventDefault();

            $(this).parent('li').addClass('is-active').siblings('li').removeClass('is-active');
          });
        }

        // Hero w/ carousel
        if ($('.c-carousel--hero').length) {
          $('.c-carousel--hero').owlCarousel({
            items: 1,
            loop: true,
            dots: false,
            autoplay: true,
            autoHeight: true,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut'
          });
        }

        // Experience w/ carousel
        if ($('.c-carousel--experience').length) {
          $('.c-carousel--experience').owlCarousel({
            items: 1,
            loop: true,
            dots: true,
            autoplay: true,
            autoplayTimeout: 7000,
            autoplayHoverPause: true,
            autoplaySpeed: 1000,
            autoHeight: false
          });
        }

        // Services w/ lightbox
        $('.c-service__title').magnificPopup({
          type: 'inline',
          preloader: false,
          gallery: {
            enabled: true
          }
        });

        // FAQs w/ accordion
        $('.c-faqs__question').click(function() {
          $(this).next().slideToggle().siblings('.c-faqs__answer').slideUp();
        });

        // Testimonials w/ carousel
        if ($('.c-carousel--testimonials').length) {
          $('.c-carousel--testimonials').owlCarousel({
            items: 1,
            loop: true,
            dots: true,
            autoplay: true,
            autoplayTimeout: 7000,
            autoplayHoverPause: true,
            autoplaySpeed: 1000,
            autoHeight: false
          });
        }

        // Google Map
        var map,
            geocoder = new google.maps.Geocoder(),
            infowindow = new google.maps.InfoWindow(),
            coordinates = [33.7987888, -116.3834897];

        var marker_icon = '/app/themes/sage/dist/images/marker.png';

        function initialize(){
          var latLng = new google.maps.LatLng(coordinates[0], coordinates[1]);

          var map = new google.maps.Map(document.getElementById('map-canvas'),{
            disableDefaultUI: true,
            draggable: false,
            scrollwheel: false,
            zoom: 17,
            zoomControl: false,
            center: latLng,
            mapTypeId: google.maps.MapTypeId.ROADMAP
          });

          geocoder.geocode({ "latLng": latLng }, function(results, status){
            if(status === google.maps.GeocoderStatus.OK){
              google.maps.event.addDomListener(window, 'resize', function(){
                map.setCenter(results[0].geometry.location);
              });

              var marker = new google.maps.Marker({
                map: map,
                position: results[0].geometry.location,
                icon: marker_icon
              });
            }
          });
        }
        google.maps.event.addDomListener(window, 'load', initialize);
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
